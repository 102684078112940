import React, { Component } from 'react'
import { formatPhoneNumber } from '../tools.js'
import { POST_CONTACTS } from '../redux/actions/ContactUs'
import { connect } from 'react-redux'
import validateInput from '../validations/ContactUs'

const defaultState = {
    name: '',
    business_name: '',
    email: '',
    phone: '',
    service: localStorage.getItem('title'),
    comment: '',
    details: '',
    isOpen: false,
    errors: {},
    loading: false
}
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = defaultState

    }

    handlePhoneChange = (e) => {
        const value = e.target.value
        const phone_number = formatPhoneNumber(value)
        console.log(value)
        this.setState({
            phone: phone_number
        })
    }


    handleInputChange = ({ target: { value, id } }) => {
        this.setState({
            [id]: value
        })
    }

    handleServiceChange = (event) => {
        localStorage.setItem('title', event.target.value === 'Other' ? 'Other': '')
        this.setState({
            service: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { postContacts } = this.props
        const serviceValue = localStorage.getItem('title')
        const { isValid, errors } = validateInput(this.state)
        this.setState({
            errors
        }, () => {
            if (isValid)
                if (this.state.service === 'Other' || serviceValue === 'Other') {
                    {
                        this.setState({ loading: true })
                        postContacts({
                            name: this.state.name,
                            business_name: this.state.business_name,
                            phone: this.state.phone,
                            service: 'Other',
                            comment: this.state.comment,
                            details: this.state.details,
                            email: this.state.email,
                            callback: () => {
                                console.log('success', this.state)
                                this.setState({
                                    isOpen: true,
                                    loading: false
                                })
                            }
                        })
                        // console.log(this.state.details)
                    }
                }
                else {
                    {
                        this.setState({ loading: true })
                        postContacts({
                            name: this.state.name,
                            business_name: this.state.business_name,
                            phone: this.state.phone,
                            service: this.state.service,
                            comment: this.state.comment,
                            email: this.state.email,
                            callback: () => {
                                console.log('success', this.state)
                                this.setState({
                                    isOpen: true,
                                    loading: false
                                })
                            }
                        })
                    }
                    // console.log(this.state.details)
                }


        })

    }


    render() {
        const serviceValue = localStorage.getItem('title')
        window.onbeforeunload = () => { localStorage.removeItem('title')}
        const { service, isOpen, errors, loading } = this.state

        return (
            <div id="contact-page">
                <div className="page_container">
                    <div className="dialog dialog-transparent">
                        <h1 className="contact-page__title">Relax, we'll take IT from here.</h1>
                        <a href="tel:7148423231"><p className="contact-page__phone"><i className="icon dnaicon-voip"></i>(714) 842 - 3231</p></a>
                        <div className="contact-form">
                            <div className="contact__image borderanimate" style={{ width: '40%' }}>
                                <img src="/imgs/contactus.png" alt="contact" />
                            </div>
                            <form className="contact-form__container" onSubmit={this.handleSubmit} >
                                <div className="f-center">
                                    <div className="input-label__container">
                                        <label htmlFor="name">Name*</label>
                                        <input type="text" id="name" name="name" value={this.state.name} onChange={this.handleInputChange} />
                                        <label className="validation_error">{errors.name}</label>
                                    </div>

                                    <div className="input-label__container">
                                        <label htmlFor="business">Business Name*</label>
                                        <input type="text" id="business_name" name="business_name" value={this.state.business_name} onChange={this.handleInputChange} autoComplete="off" />
                                        <label className="validation_error">{errors.business}</label>
                                    </div>

                                </div>
                                <div className="f-center">
                                    <div className="input-label__container">
                                        <label htmlFor="email">Email*</label>
                                        <input type="text" id="email" name="email" value={this.state.email} onChange={this.handleInputChange} autoComplete="off" />
                                        <label className="validation_error">{errors.email}</label>
                                    </div>

                                    <div className="input-label__container">
                                        <label htmlFor="phone">Phone Number*</label>
                                        <input type="text" id="phone" name="phone" value={this.state.phone} onChange={(e) => this.handlePhoneChange(e)}
                                            autoComplete="off" minLength="14" maxLength="14" />
                                        <label className="validation_error">{errors.phone}</label>
                                    </div>

                                </div>
                                <div className="service">
                                    <div className="input-label__container" style={{ 'width': '100%' }}>
                                        <label htmlFor="phone">What is your IT Service Need?</label>
                                        <select id="myselect" value={this.state.service} onChange={this.handleServiceChange} required>
                                            <option defaultValue selected hidden>{serviceValue}</option>
                                            <option>Lower My IT Costs</option>
                                            <option>Simplifying IT</option>
                                            <option>Workflow Efficiency</option>
                                            <option>IT Services</option>
                                            <option>Data Backup and Disaster Recovery</option>
                                            <option>VOIP Services</option>
                                            <option>Network and Cybersecurity Services</option>
                                            <option>Cloud Services</option>
                                            <option>Business Continuity Strategies</option>
                                            <option>Printers</option>
                                            <option>All of the Above</option>
                                            <option>Other</option>
                                        </select>
                                        <label className="validation_error">{errors.service}</label>
                                    </div>

                                </div>

                                {serviceValue  ===  'Other' &&
                                    <div className="other">
                                        <div className="input-label__container" style={{ 'width': '100%' }}>
                                            <label htmlFor="details">Details</label>
                                            <input type="text" id="details" name="details" className="other-input" value={this.state.details} onChange={this.handleInputChange} />
                                            <label className="validation_error">{errors.details}</label>
                                        </div>
                                    </div>
                                }
                                <div className="input-label__container" style={{ 'width': '100%' }}>
                                    <label htmlFor="comment">Comment</label>
                                    <textarea type="text" id="comment" name="comment" className="comment" value={this.state.comment} onChange={this.handleInputChange}></textarea>

                                </div>
                                <div className="input-label__container">
                                    <button type="submit" className="submit-btn" disabled={loading} >Submit</button>
                                </div>

                            </form>

                            {loading && (
                                <div id="preloader_wrapper" >
                                    <div className="preloader"></div>
                                </div>
                            )}
                            <div id='email_sent' className={`success_modal ${isOpen ? 'show_modal' : 'hide_modal'}`}>
                                <div className='modal_content'>
                                    <div className='modal_close'>
                                        <i id="close_btn" className='dnaicon dnaicon-close-round' onClick={() => this.setState(defaultState)}></i>
                                    </div>
                                    <img className='modal_image' src="/imgs/Check.png" alt='checkimage' />
                                    <p className='modal_msg'>Thank you for your interest in our service.</p>
                                    <p className='modal_msg'>We'll get back to you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postContacts: (payload) => {
            dispatch({ type: POST_CONTACTS, payload })
        }
    }
}

export default connect(null, mapDispatchToProps)(ContactUs)