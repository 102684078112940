import React, { Component } from 'react'



export default class GT_OrderVerification extends Component {
    render() {
        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Order Verification Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology Order Verification Policy</h1>
                    <p className="terms__description--padding-small">This is an Agreement between You - Customer of our Hosting Services, and Corporate Technology a US company headquartered in California, U.S.A Legal detail about the company can be found in the 'Customer Service Contact Details' section of this document. For the sake of brevity, Corporate Technologies will be referred to as 'Corporate Technology' in this document.</p>
                    <p className="terms__description--padding-small">This document contains all details about our order verification procedure. It aims to fill you in on our policy towards Internet fraud. Our company's policy is to strictly scrutinize each new order and verify the payment details of each new customer.</p>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>How does the verification procedure take place?</dt>
                                <dd className='sub_detail_title'>Step 1. <span>You place an order for a hosting account with us through our order form. A money transaction follows through the selected payment method.</span></dd>
                                <dd className='sub_detail_title'>Step 2. <span>We receive a notification email from our authorized retailer (PayPal, WorldPay, 2CheckOut) for the transaction.</span></dd>
                                <dd className='sub_detail_title'>Step 3. <span>An order verification procedure commences within one hour after the notification has been received (on weekends this period may be longer).</span></dd>
                                <dd className='sub_detail_title'>Step 4. <span>A sales representative of ours compares your IP location with your billing address and the location information given in your hosting account.</span></dd>
                                <dd className='sub_detail_title'>Step 5. <span>Payment confirmation by phone is made at a time convenient for you. If we can’t reach you by phone, we’ll send you an email, asking you to present us with another phone number which you can be reached to confirm the payment. If within 48 hours you still haven’t confirmed the payment, the order will be canceled and the account will be blocked.</span></dd>
                                <dd className='sub_detail_title'>IMPORTANT: <span>All new PayPal payments come to us with a status ‘Authorized’, but no funds are cleared into our account yet. Within 48 hours the ‘Authorized’ status should be updated to either ‘Capture’ (if we approve the payment), or ‘Void’ (if we fail to approve the payment). The funds will be cleared into our account as soon as (and only if) the payment goes into ‘Capture’ status. Please, MIND that your successfully cleared payment will consequently show up with the following statuses - ‘Authorized’ and ‘Capture’, in your PayPal statement. This does NOT mean that you have been charged twice for the service.</span></dd>
                                <dd>We take no responsibility if, during the order verification process, the domain chosen by you has been registered by someone else, since we register domain names only after the payment has been confirmed. If this is the case, then you might wish to register another domain(s).</dd>
                            <dt>CUSTOMER SERVICE CONTACT DETAILS</dt>
                                <dd>Customers can get in touch with our Customer Care representatives via any of the options listed below.</dd>
                                <dd>Technical Support</dd>
                                <dd>Via the ticketing systems - open a trouble ticket from the Web Hosting Control Panel (section Help Center - > Open New Ticket). 1-hour support response is guaranteed! (anchor to 1-hour Support Response Guarantee from Service Level Guarantees popup)</dd>
                                <dd className='sub_detail_title'>Pre-sale and general questions</dd>
                                    <ol>
                                        <li>Phone us at: <a href="tel:7148423231"> US Phone +1 (714) 842-3231</a></li>
                                        <li>Use our Live Chat</li>
                                        <li>Send us your details:  <a href="https://www.corporatetech.com/contact-us">Contact Form.</a></li>
                                    </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
