import { takeLatest, call, put } from "redux-saga/effects"
import { POST_CONTACTS } 
         from '../actions/ContactUs'
import axios from 'axios'
const baseURL = 'https://corporate-api.dnaqa.net'
// const baseURL = 'https://localhost:4999'

function* PostContacts({ payload }) {
  if (!payload) {
    return
  }
    const { callback = () => {} } = payload
    delete payload.callback
    const proxyurl = "https://cors.bridged.cc/"
      let config = {
        method: 'post',
        url: `${proxyurl}${baseURL}/contact-us`,
        // url: `${baseURL}/contact-us`,
        data: payload,
        auth: {
          username: 'corptech',
          password: 'F0nch3rt0'
        }
      }
      try {
        const result = yield call(axios, config)
        callback()
        yield put({ type: POST_CONTACTS, result })
        console.log('success')
      } catch (e) {
    } console.log('fail')
      
  }

export default function* (){
    yield takeLatest(POST_CONTACTS, PostContacts)
  }